<template>
  <div>
    <b-card>
      <template #header>
        <h4>Expiring Order</h4>
        <b-checkbox v-model="showExpired">Show Expired</b-checkbox>
      </template>

      <b-skeleton-img v-if="loader.table"></b-skeleton-img>

      <b-tabs>
        <b-tab v-for="(items, agentCode) in groupedExpiring" :key="agentCode" :title="agentCode">
          <b-card no-body>
            <b-table
              :items="items"
              :fields="table.fields"
              sort-by="dueDate"
              :current-page="table.paging.pageNo"
              :per-page="table.paging.pageSize"
              :total-rows="items.length"
              small
              bordered
              fixed
              striped
              show-empty
            >
              <template #empty>
                <div class="d-flex p-3">
                  <h4 class="ml-auto mr-auto">There are no expiring orders</h4>
                </div>
              </template>
              <template #cell(dueDate)="data">
                <h5 :class="colorStatus(getExpiryStatus(data.value).remaining)">{{ getExpiryStatus(data.value).text }}</h5>
              </template>
              <template #cell(client)="data">
                <div>{{ data.item.contactName }}</div>
                <div>
                  <b-link v-if="data.item.whatsapp" class="mr-1" :href="`https://wa.me/${data.item.whatsapp}`" target="_blank">whatsapp</b-link>
                  <b-link v-if="data.item.telegram" :href="`https://t.me/${data.item.telegram}`" target="_blank">telegram</b-link>
                </div>
              </template>
              <template #cell(action)="data">
                <b-button size="sm" variant="success" @click.stop="extend(data.item)">Extend</b-button>
              </template>
            </b-table>
            <template #footer>
              <b-pagination v-model="table.paging.pageNo" :totalRows="items.length" :per-page="table.paging.pageSize" align="center" class="mt-3"></b-pagination>
            </template>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import api from "@api";

export default {
  name: "ExpiringOrder",
  components: {},
  data() {
    return {
      expiringList: [],
      showExpired: true,
      table: {
        fields: [
          { key: "orderNo", label: "Order #" },
          { key: "client", label: "Client" },
          { key: "brandName", label: "Brand" },
          { key: "dueDate", label: "Due", sortable: true, class: `text-right` },
          { key: "action", label: "", class: `text-right` },
        ],
        paging: { pageNo: 1, pageSize: 10 },
      },
      loader: { table: false },
    };
  },
  computed: {
    groupedExpiring() {
      return this.groupByAgentCode(this.expiringList);
    },
  },
  async mounted() {
    await this.getExpiringOrder();
  },
  methods: {
    async getExpiringOrder() {
      this.loader.table = true;
      try {
        this.expiringList = await api.get(`dashboard/order/expiring`);
      } catch (error) {
        console.error(error);
      } finally {
        this.loader.table = false;
      }
    },
    groupByAgentCode(list) {
      if (!list) return {};

      if (!this.showExpired) list = list.filter(item => moment(item.dueDate).isAfter(moment()));

      return list.reduce((acc, item) => {
        const agentCode = item.agentCode;
        if (!acc[agentCode]) {
          acc[agentCode] = [];
        }
        acc[agentCode].push(item);
        return acc;
      }, {});
    },
    getExpiryStatus(dueDate) {
      const now = moment();
      const due = moment(dueDate);

      if (due.isBefore(now)) {
        return { text: "Expired", remaining: -1 };
      }

      const duration = moment.duration(due.diff(now));

      if (duration.asDays() >= 1) {
        return { text: `${Math.floor(duration.asDays())} days`, remaining: duration.asDays() };
      } else if (duration.asHours() >= 1) {
        return { text: `${Math.floor(duration.asHours())} hours`, remaining: duration.asHours() };
      } else {
        return { text: `${Math.floor(duration.asMinutes())} minutes`, remaining: duration.asMinutes() };
      }
    },
    colorStatus(remaining) {
      if (remaining < 0) {
        return "text-danger";
      } else if (remaining > 0 && remaining < 2) {
        return "text-warning";
      } else if (remaining > 3) {
        return "text-info";
      } else {
        return "text-success";
      }
    },
  },
};
</script>
