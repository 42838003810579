<template>
  <div class="component-wrapper">
    <!-- <currency-rate /> -->
    <expiring-order />
  </div>
</template>

<script>
import api from "@api";
import CurrencyRate from "./components/CurrencyRate.vue";
import ExpiringOrder from "./components/ExpiringOrder.vue";

export default {
  name: `MainDashboard`,
  components: { ExpiringOrder, CurrencyRate },
  computed: {},
  methods: {},
};
</script>
